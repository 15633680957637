<template>
   <div class="min-h-100 d-flex flex-column h-100">
      <div class="row">
         <div class="col-xxl-12">
            <div>
               <button
                  type="submit"
                  v-b-modal.modal-1
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_1"
                  class="btn btn-xs btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3 my-3 mr-3"
               >
                  <i v-if="sending" class="fas fa-spinner fa-spin"></i>
                  {{ $t('ACTION.INTERN.CREATE') }}
               </button>
            </div>
            <b-modal
               @ok="validation()"
               @hidden="clearInputs()"
               @hide="clearInputs()"
               ref="modal"
               ok-title="Créer"
               cancel-title="Annuler"
               id="modal-1"
               :ok-disabled="$v.form.$invalid"
               :title="$t('ACTION.INTERN.CREATE')"
            >
               <form ref="form" class="gutter-t" @submit.stop.prevent="">
                  <div>
                     <div class="mb-5 my-1">
                        <b-form-group
                           label="Nom* :"
                           label-for="internLastName"
                           class="mb-2"
                           invalid-feedback="Veuillez saisir un nom"
                           :state="validateState('internLastName')"
                           data-app
                        >
                           <b-form-input
                              required
                              id="internLastName"
                              :state="validateState('internLastName')"
                              ref="internLastName"
                              v-model="$v.form.internLastName.$model"
                           ></b-form-input>
                        </b-form-group>
                     </div>
                     <div class="mb-5 my-1">
                        <b-form-group
                           label="Prénom* :"
                           label-for="internFirstName"
                           class="mb-2"
                           invalid-feedback="Veuillez saisir un prénom"
                           :state="validateState('internFirstName')"
                           data-app
                        >
                           <b-form-input
                              required
                              id="internFirstName"
                              :state="validateState('internFirstName')"
                              ref="internFirstName"
                              v-model="$v.form.internFirstName.$model"
                           ></b-form-input>
                        </b-form-group>
                     </div>
                     <div class="mb-5 my-1">
                        <b-form-group
                           label="Date de naissance* :"
                           label-for="internBirthDate"
                           class="mb-2"
                           invalid-feedback="Veuillez saisir une date de naissance valide"
                           :state="validateState('internBirthDate')"
                           data-app
                        >
                           <b-form-input
                              type="date"
                              required
                              ref="internBirthDate"
                              placeholder="08/04/2001"
                              :state="validateState('internBirthDate')"
                              id="internBirthDate"
                              v-model="$v.form.internBirthDate.$model"
                           ></b-form-input>
                        </b-form-group>
                     </div>
                     <div class="mb-5 my-1">
                        <b-form-group
                           label="Email* :"
                           label-for="internalEmail"
                           class="mb-2"
                           invalid-feedback="Veuillez saisir un email valide"
                           :state="validateState('internalEmail')"
                           data-app
                        >
                           <b-form-input
                              type="email"
                              required
                              ref="internalEmail"
                              v-model="$v.form.internalEmail.$model"
                              :state="validateState('internalEmail')"
                           ></b-form-input>
                        </b-form-group>
                     </div>
                     <div class="mb-5 my-1 v-select-toggle">
                        <b-form-group
                           label="Stagiaire rattaché à l'établissement* :"
                           label-for="internalCostumer"
                           class="mb-2"
                           invalid-feedback="Veuillez sélectionner un établissement"
                           :state="validateState('internalCostumer')"
                           data-app
                        >
                           <CustomSelect
                              @change="customerSS()"
                              id="internalCostumer"
                              :options="customersOptions"
                              name="internalCostumer_id"
                              :state="validateState('internalCostumer')"
                              v-model="$v.form.internalCostumer.$model"
                              searchable
                              :labelSearchPlaceholder="title"
                              labelNotFound="Aucun résultat pour"
                           />
                        </b-form-group>
                     </div>
                     <div class="mb-5 my-1">
                        <b-form-group
                           v-if="secondary_siteOptions.length !== 0"
                           label="Stagiaire rattaché à un site secondaire"
                           label-for="secondary_site_id"
                           class="mb-2"
                           invalid-feedback="Veuillez sélectionner un site secondaire"
                        >
                           <b-form-select
                              id="secondary_site"
                              :options="secondary_siteOptions"
                              name="secondary_site_id"
                              v-model="$v.form.secondary_site.$model"
                              :required="secondary_siteOptions.length !== 0"
                           />
                        </b-form-group>
                     </div>
                  </div>
               </form>
            </b-modal>
            <div class="card card-custom card-stretch gutter-b">
               <div class="card-header border-0 pt-5 pb-5">
                  <h3 class="card-title align-items-start justify-content-center flex-column">
                     <span class="card-label font-weight-bolder text-dark">{{ $t('GENERAL.FILTER') }}</span>
                     <span class="text-muted mt-3 font-weight-bold font-size-sm">
                        {{
                           Object.keys($route.query).length === 0
                              ? $t('INTERN.FILTER_DESC', {
                                   number: interns.meta && interns.meta.total ? interns.meta.total : 0
                                })
                              : `${interns.meta && interns.meta.total ? interns.meta.total : 0} ${$t('GENERAL.RESULTS')}`
                        }}</span
                     >
                  </h3>
                  <div class="card-toolbar">
                     <router-link
                        v-if="isFiltered()"
                        :to="{ name: $route.name, params: $route.params }"
                        class="btn btn-sm btn-info font-weight-bold text-uppercase px-lg-9 py-lg-3 mr-3"
                     >
                        {{ $t('GENERAL.RESET') }}
                     </router-link>

                     <button
                        type="button"
                        v-on:click="triggerDisplayFilter"
                        class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                     >
                        {{ displayFilter ? $t('GENERAL.HIDE_FILTER') : $t('GENERAL.SHOW_FILTER') }}
                     </button>
                  </div>
               </div>
               <transition name="slide-fade">
                  <div v-if="displayFilter">
                     <form @submit="onSubmit">
                        <div class="card-body">
                           <div class="row">
                              <div class="col-xl-6">
                                 <div class="form-group">
                                    <label>{{ $t('INTERN.LABEL.LASTNAME') }}</label>
                                    <b-form-input class="form-control form-control-solid" v-model="paramSearch.lastname" />
                                    <span class="form-text text-muted">{{ $t('INTERN.TIP.LASTNAME') }}</span>
                                 </div>
                              </div>
                              <div class="col-xl-6">
                                 <div class="form-group">
                                    <label>{{ $t('INTERN.LABEL.FIRSTNAME') }}</label>
                                    <b-form-input class="form-control form-control-solid" v-model="paramSearch.firstname" />
                                    <span class="form-text text-muted">{{ $t('INTERN.TIP.FIRSTNAME') }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="card-footer py-3">
                           <div class="row">
                              <div class="col-xxl-12 text-center">
                                 <button
                                    type="submit"
                                    class="btn btn-xs btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                                    :class="sending && 'disabled'"
                                 >
                                    <i v-if="sending" class="fas fa-spinner fa-spin"></i>
                                    {{ $t('GENERAL.FILTER') }}
                                 </button>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </transition>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-12">
            <div class="card card-custom gutter-b">
               <div class="card-body p-0">
                  <Skeleton3 v-if="loading" />
                  <InternTable v-else-if="interns.data && interns.data.length > 0" :sortBy="sortBy" @change-sort="changeSort" />
                  <div v-else-if="!loading && interns.data && interns.data.length === 0" class="col-xxl-12">
                     <div class="alert alert-info mx-auto my-3 w-50 text-center">
                        {{ $t('GENERAL.NO_RESULT') }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row mt-auto">
         <div class="col-xxl-12">
            <div>
               <b-pagination-nav
                  v-if="interns && interns.meta"
                  class="mb-0"
                  align="center"
                  size="lg"
                  use-router
                  v-model="currentPage"
                  :number-of-pages="interns.meta.last_page"
                  :link-gen="linkGen"
               >
               </b-pagination-nav>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { RESET_TOOLBAR, SET_TOOLBAR } from '@/core/services/store/config.module.js';
import { LIST_INTERNS, POST_INTERN } from '@/core/services/store/api/intern';
import Skeleton3 from '@/view/content/skeleton/Skeleton3';
import InternTable from '@/view/pages/intern/widget/Table';
import $ from 'jquery';
import { LIST_SECONDARY_SITE } from '@/core/services/store/api/secondary_site';
import VSelect from '@alfsnd/vue-bootstrap-select';
import { LIST_CUSTOMERS_WITH_FILLIALE } from '@/core/services/store/api/customer';

export default {
   mixins: [validationMixin],

   data() {
      return {
         currentPage: this.$route.query.page ? this.$route.query.page : 1,
         sortBy: 'name',
         sortByApi: 'lastname,firstname',
         perPage: 50,
         loading: true,
         displayFilter: false,
         sending: false,
         paramSearch: {
            firstname: this.$route.query.firstname,
            lastname: this.$route.query.lastname
         },
         form: {
            internFirstName: null,
            internLastName: null,
            internalEmail: null,
            internBirthDate: null,
            internalCostumer: null,
            secondary_site: null
         },
         customer: 'Sélectionnez un établissement',
         title: 'Rechercher un établissement',
         customersOptions: [],
         secondary_siteOptions: [],
         locale: 'fr',
         disabled: false,
         labels: {
            fr: {
               labelPrevDecade: 'Décennie précédente',
               labelPrevYear: 'Année précédente',
               labelPrevMonth: 'Mois précédent',
               labelCurrentMonth: 'Mois actuel',
               labelNextMonth: 'Mois suivant',
               labelNextYear: 'Année suivante',
               labelNextDecade: 'Décennie suivante',
               labelToday: "Aujourd'hui",
               labelSelected: 'Sélectionné',
               labelNoDateSelected: 'Aucune date sélectionnée',
               labelCalendar: 'Calendrier',
               labelNav: 'Navigation',
               labelHelp: 'Aide'
            }
         }
      };
   },
   components: {
      Skeleton3,
      InternTable,
      CustomSelect: VSelect
   },
   mounted() {
      this.resetError;
      $(this.$refs.modal).on('hidden.bs.modal', () => {
         this.clearInputs();
      });
      this.getCustomers();
      this.setToolbar();
      this.setPageTitle();
      if (this.isFiltered()) this.displayFilter = true;
      this.getInterns();
   },
   validations() {
      return {
         form: {
            internLastName: {
               required,
               maxLength: 255
            },
            internFirstName: {
               required,
               maxLength: 255
            },
            internalEmail: {
               email
            },
            internBirthDate: {
               required,
               validateDateOfBirth: this.validateDateOfBirth
            },
            internalCostumer: {
               required
            }
         }
      };
   },
   methods: {
      validateState(name) {
         const { $dirty, $error } = this.$v.form[name];
         return $dirty ? !$error : null;
      },
      validateDateOfBirth() {
         const minDate = new Date();
         minDate.setFullYear(minDate.getFullYear() - 100);
         const maxDate = new Date();
         maxDate.setFullYear(maxDate.getFullYear() - 16);

         const selectedDate = new Date(this.form.internBirthDate);

         if (selectedDate < minDate || selectedDate > maxDate) {
            // Date is not within the range of 16 to 100 years
            return false;
         }
         return this.form.date_of_birth === null ? null : true;
      },
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.INTERNS'));
      },
      isFiltered() {
         if (Object.keys(this.$route.query).length > 0) {
            return !(this.$route.query.page && Object.keys(this.$route.query).length === 1);
         } else {
            return false;
         }
      },
      triggerDisplayFilter() {
         this.displayFilter = this.displayFilter !== true;
      },
      onSubmit(e) {
         this.sending = true;
         const routerParams = {};
         if (this.paramSearch.firstname) routerParams.firstname = this.paramSearch.firstname;
         if (this.paramSearch.lastname) routerParams.lastname = this.paramSearch.lastname;
         this.$router
            .push({
               name: this.$route.name,
               params: this.$route.params,
               query: routerParams
            })
            .then((this.sending = false))
            .catch(() => (this.sending = false));
         e.preventDefault();
      },
      async getInterns() {
         const params = {
            page: this.currentPage,
            perpage: this.perPage,
            sort: this.sortByApi
         };

         if (this.$route.query.firstname) params['filter[search_firstname]'] = this.$route.query.firstname;
         if (this.$route.query.lastname) params['filter[search_lastname]'] = this.$route.query.lastname;

         await this.$store
            .dispatch(LIST_INTERNS, { params })
            .catch((error) => {
               if (error.status === 404) {
                  this.$router.push({ name: '404' });
               }
            })
            .finally(
               setTimeout(() => {
                  this.loading = false;
               }, 200)
            );
      },
      setToolbar() {
         this.$store.dispatch(SET_TOOLBAR, this.$t('TIP.INTERN.TABLE'));
      },
      linkGen(pageNum) {
         const query = {};
         Object.assign(query, this.$route.query);
         query.page = pageNum;
         return {
            name: this.$route.name,
            params: this.$route.params,
            query: query
         };
      },
      changeSort(payload) {
         this.sortByApi = payload.dir ? `-${payload.sortBy}` : payload.sortBy;
         this.getInterns();
      },
      validation() {
         const params = {
            firstname: this.form.internFirstName,
            lastname: this.form.internLastName,
            email: this.form.internalEmail,
            date_of_birth: this.form.internBirthDate,
            customer_id: this.form.internalCostumer.value,
            secondary_site_id: this.form.secondary_site,
            role: 'Utilisateur',
            services: [{ id: 2, role: 2, offer: null }]
         };
         //call api to create a intern
         this.$store
            .dispatch(POST_INTERN, params)
            .then(() => {
               this.clearInputs();
               this.makeToast('success', this.$t('INTERN.ACTION.SUCCESS'), this.$t('INTERN.ACTION.CREATE.SUCCESS'));
               this.getInterns();
            })
            .catch((e) => {
               if (e.data.errors.date_of_birth) {
                  this.makeToast('danger', this.$t('INTERN.ACTION.FAIL'), e.data.errors.date_of_birth[0]);
               } else if (e.data.errors.email) {
                  this.makeToast('danger', this.$t('INTERN.ACTION.FAIL'), e.data.errors.email[0]);
               } else {
                  this.makeToast('danger', this.$t('INTERN.ACTION.FAIL'), this.$t('INTERN.ACTION.FAIL'));
               }
            });
      },
      clearInputs() {
         this.form.internLastName = null;
         this.form.internFirstName = null;
         this.form.internalEmail = null;
         this.form.internBirthDate = null;
         this.form.internalCostumer = null;

         this.$v.form.$reset();
      },
      makeToast(variant = null, title = null, message = null) {
         this.$bvToast.toast(message, {
            title: title,
            autoHideDelay: 3000,
            variant: variant
         });
      },
      customerSS() {
         this.secondary_siteOptions = [];
         // SEARCH THROUGH API IF A CUSTOMER HAVE A SECONDARY_SITE
         this.$store.dispatch(LIST_SECONDARY_SITE, this.customer).then((result) => {
            result.data.forEach((secondary_site) => {
               this.secondary_siteOptions.push({
                  text: secondary_site.site,
                  value: secondary_site.id
               });
            });
         });
      },
      getCustomers() {
         const customer_id = this.$store.getters.currentUser.customer.id;
         this.$store.dispatch(LIST_CUSTOMERS_WITH_FILLIALE, customer_id).then((customers) => {
            customers.forEach((customer) => {
               this.customersOptions.push({
                  text: customer.name,
                  value: customer.id
               });
            });
            this.customersOptions.sort((a, b) => a.text.localeCompare(b.text));
         });
      }
   },
   beforeRouteLeave(to, from, next) {
      this.$store.dispatch(RESET_TOOLBAR).then(next);
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         interns: 'listInterns'
      }),
      ...mapState({
         error: (state) => state.action.error
      })
   },
   watch: {}
};
</script>
